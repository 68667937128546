/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sortCaret, headerSortingClasses, remoteGetSelectRow, NoRecordsFoundMessage, convertPriceCurrency, UIDates } from '_metronic/_helpers';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import { getConnection } from 'constants/connectionSettings';
import * as UIHelpers from '../_context/DocumentsUIHelpers';
import { useLoadingHandler, useQuery } from 'app/hooks';
import * as columnFormatters from './column-formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from '../_redux/actions';
import * as columnHelpers from './columnHelpers';
import { Icon } from '_metronic/_icons';
import clsx from 'clsx';



export function DocumentsTable({ documents, loading, countDocuments, queryParams, setOrderQuery, setPaginationQuery, setFilterQuery, connectionEntities }) {

  const query = useQuery();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // Documents UI Context
  const UIContext = useDocumentsUIContext();
  const contextLocal = useDocumentLocalUIContext();
  const { type: documentType, title: documentTitle } = useMemo(() =>
    UIContext.requestedDocument,
    [UIContext.requestedDocument,]
  );

  const UIProps = useMemo(() => ({
    openEditDocumentDialog: UIContext.openEditDocumentDialog,
    openDeleteDocumentDialog: UIContext.openDeleteDocumentDialog,
    openDocumentConvertToWaybill: UIContext.openDocumentConvertToWaybill,
    openDocumentConvertToCredit: UIContext.openDocumentConvertToCredit,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    copyDocumentForCreateNewOne: UIContext.copyDocumentForCreateNewOne,
    openPaymentReminderDialog: UIContext.openPaymentReminderDialog,
    openPaymentWarningsDialog: UIContext.openPaymentWarningsDialog,
    openEnterPaymentDialog: UIContext.openEnterPaymentDialog,
    openDocumentConvertToInvoice: UIContext.openDocumentConvertToInvoice,
    openDocumentConvertToOrder: UIContext.openDocumentConvertToOrder,
    openLabelSettingDialog: UIContext.openLabelSettingDialog,
    openCustomerDetailsDialog: UIContext.openCustomerDetailsDialog,
    openPrintLabelsDialog: UIContext.openPrintLabelsDialog,
    pushConvertDocument: UIContext.pushConvertDocument,
    openConvertDocumentAlertDialog: UIContext.openConvertDocumentAlertDialog,
    openDocumentDetailsDialog: UIContext.openDocumentDetailsDialog,
    requestedDocument: UIContext.requestedDocument,
    documentQueryParams: contextLocal.queryParams,
    openLabelsTable: UIContext.openLabelsTable,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    openConvertDocumentInfoDialog: UIContext.openConvertDocumentInfoDialog,
    openExportDocumentDialog: UIContext.openExportDocumentDialog,
  }),
    [UIContext, contextLocal.queryParams]
  );

  const useLoading = useLoadingHandler(loading, DocumentActions.cleanStatesInStore({ loading: false }));

  const amazonConnection = connectionEntities?.find(item => item.name === 'amazon')?.amazon || {};
  const { invoice_upload: isAmazonInvoiceUpload, credit_upload: isAmazonCreditUpload } = amazonConnection;

  /*   const tableRowHandler = (rowId, key, event) => {
      dispatch(DocumentActions.updateDocumentByField(rowId, key, event));
    }; */

  const documentConvertHandler = (id, from, to, isDuplicated, isDuplicateInvoiceId) => {
    if (from === "waybills" && isDuplicateInvoiceId) {
      return UIProps.openConvertDocumentInfoDialog({ isDuplicateInvoiceId });
    }
    if (isDuplicated) {
      return UIProps.openConvertDocumentAlertDialog({ id, from, to });
    }
    UIProps.pushConvertDocument(id, from, to);
  };


  const openPrintDocumentDialog = (id) => {

    if (id) {
      const data = {
        ids: [id],
        type: documentType,
      };

      dispatch(DocumentActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };


  const COLUMNS = [
    {
      dataField: 'id',
      //FIXME: Warning: Failed prop type: Invalid prop `column.text` of type `array` supplied to `HeaderCell`, expected `string`.
      text: intl({ id: columnHelpers?.idColumnText(documentType) }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'text-nowrap',
      headerStyle: { minWidth: '100px' },
      style: { height: '50px' },
      formatter: (cell, row) => {
        const orderStatus = UIHelpers.ORDER_STATUS.find(item => item.value === row.order_status)
        return (
          <OverlayTrigger overlay={<Tooltip id="market-tooltip">{documentType === 'orders' ? orderStatus?.status ?? <FormattedMessage id="GENERAL.OTHER" /> : <FormattedMessage id="SETTINGS.DOCUMENT_REVIEW" />}</Tooltip>}>
            <a type="button" className="d-flex align-items-center text-decoration-none text-hover-primary preview" onClick={() => UIProps.openDocumentDetailsDialog(cell)}>
              {documentType === 'orders' && <span className={clsx("label label-xl label-dot mr-2", `label-${orderStatus?.className}`)}></span>}
              <span className='font-weight-bold'>{cell}</span>
              <span className='invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                <Icon.FileEarmarkText />
              </span>
            </a>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'relatedInvoiceId',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.INVOICE_ID' }),
      sort: true,
      sortCaret,
      hidden: !['credits', 'waybills'].includes(documentType),
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'font-weight-bold',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => row.history?.find(history => history.document_from === "invoice" && history.document_to === "credit")?.relation_id ?? (row?.related_invoice_id ?? ''),
    },
    {
      dataField: documentType === 'orders' ? 'order_date' : 'created_at',
      text: intl({ id: columnHelpers?.dateColumnText(documentType) }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap text-center',
      classes: 'text-nowrap text-center pr-4',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => (
        documentType === 'orders'
          ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(row.order_date)}</Tooltip>}>
            <span className={`label label-inline label-rounded ${UIDates.isOrderOlderThan(row.order_date, 2) ? 'label-outline-danger' : 'label-outline-success'}`}>
              {UIDates.formNow(row.order_date)}
            </span>
          </OverlayTrigger>
          : <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(documentType === 'orders' ? row.order_date : row.created_at)}</Tooltip>}>
            <span>
              {UIDates.formatDate(documentType === 'orders' ? row.order_date : row.created_at)}
            </span>
          </OverlayTrigger>
      ),
    },
    {
      dataField: 'position',
      isDummyField: true,
      hidden: !['orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '320px' },
      style: { fontSize: '11px' },
      formatter: (cell, row, rowIndex) => <columnFormatters.ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'customer_details.name',
      text: intl({ id: 'CUSTOMER_TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '200px' },
      formatter: (cell, row) => {
        return (
          <a type="button" className="d-flex align-items-center text-decoration-none text-hover-primary text-nowrap preview" onClick={() => UIProps.openCustomerDetailsDialog(row.id)} id='btn_customer_preview'>
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold'>{row.customer_details.name}</span>
              <span className='text-dark-50'>{row.customer_details.email}</span>
            </div>
            <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
              <Icon.FileEarmarkText />
            </span>
          </a>
        );
      },
    },
    {
      dataField: 'customer_details.delivery_address.country_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const location = [];
        if (row.customer_details?.delivery_address?.country_code) {
          location.push(row.customer_details?.delivery_address?.country_code);
        }
        if (row.customer_details?.delivery_address?.post_code) {
          location.push(row.customer_details?.delivery_address?.post_code);
        }
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.customer_details.delivery_address.city}</span>
            <span className='text-dark-50'>{location.join('-')}</span>
          </div>
        );
      },
    },
    {
      dataField: 'order_id',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      hidden: ['offers'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'text-nowrap',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {

        const orderInfo = <>
          <span className='svg-icon svg-icon-lg mr-2'>
            {getConnection(row.marketplace)?.icon ?? row.marketplace}
          </span>
          <span className='font-weight-bold'>
            {row.order_number ? `#${row.order_number}` : row.order_id ? `#${row.order_id}` : ''}
          </span>
        </>

        return row.marketplace_url !== ''
          ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row.marketplace_url}</Tooltip>}>
            <a href={row.marketplace_url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-muted text-hover-primary">
              {orderInfo}
            </a>
          </OverlayTrigger>
          : <div className="text-muted">
            {orderInfo}
          </div>

      },
    },
    {
      dataField: 'entered_payment',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      hidden: !['invoices', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-muted font-weight-bold pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {

        const payments = cell?.map(payment => {
          const method = UIHelpers.paymentMethods.find(method => method.value === payment.payment_method);
          return method ? method.labelId ? intl({ id: method.labelId }) : method.label : payment.payment_method;
        });

        return [...new Set(payments)].join(', ');
      }
    },
    {
      dataField: 'payment_status',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      hidden: !['invoices', 'orders', 'credits'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          <span type='button' className={`label label-inline label-lg font-weight-bold label-${cell === 'paid' ? 'outline-success' : 'danger'}`} onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
            {UIHelpers.paymentStatus.find(payment => payment.value === cell)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
          </span>
        );
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.TOTAL_AMOUNT' }),
      hidden: !['invoices'].includes(documentType),
      isDummyField: true,
      classes: 'font-weight-bold text-right pl-3',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + totalPriceDiscount;
        const currency = row.pricing_summary.currency;
        return (
          <span type="button" onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
            {convertPriceCurrency(totalPayment, currency)}
          </span>
        )
      },
    },
    {
      dataField: 'rest',
      isDummyField: true,
      hidden: !['invoices', 'orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.OPEN_AMOUNT' }),
      headerClasses: 'text-right',
      classes: 'font-weight-bold text-right',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.RestPriceColumnFormatter,
      formatExtraData: {
        openEnterPaymentDialog: UIProps.openEnterPaymentDialog,
        openPaymentWarningsDialog: UIProps.openPaymentWarningsDialog,
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.AMOUNT' }),
      hidden: !['orders'].includes(documentType),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-right',
      classes: 'font-weight-bold text-right pr-7 pl-3',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const priceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + priceDiscount;
        const currency = row.pricing_summary.currency;
        return convertPriceCurrency(totalPayment, currency);
      },
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'GENERAL.LABEL' }),
      hidden: ['credits', 'waybills'].includes(documentType),
      headerClasses: 'text-center',
      classes: 'text-center',
      headerStyle: { minWidth: '80px' },
      formatter: columnFormatters.ShippingDataColumnFormatter,
      formatExtraData: {
        orderStatusOpen: !['completed', 'cancelled'].includes(query.get('filter.status')),
        dispatch,
        openLabelsTable: UIProps.openLabelsTable,
        openPrintLabelsDialog: UIProps.openPrintLabelsDialog,
        openLabelSettingDialog: UIProps.openLabelSettingDialog,
      },
    },
    {
      dataField: 'history',
      hidden: !['orders'].includes(documentType),
      text: intl({ id: 'DOCUMENT.INVOICE_TITLE' }),
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        const invoice = cell?.find(item => item?.action === 'convert_document' && item?.document_to === "invoice" && item?.status === 'success');
        return (
          invoice && <OverlayTrigger overlay={<Tooltip id="invoice-tooltip">
            <div className='font-weight-bolder border-bottom mb-2'>
              <FormattedMessage id='DOCUMENT.TABLE.COLUMN.INVOICE_ID' />
            </div>
            <div className='font-weight-bold'>
              {invoice.relation_id}
              <div className='font-size-sm text-dark-50'>
                {UIDates.formatDateTime(invoice.created_at)}
              </div>
            </div>
          </Tooltip>}>
            <Link to={`${ModuleRoutes.DOCUMENT_INVOICE}?page=1&search=${invoice.relation_id}`} type='button' className='svg-icon text-dark-50 text-hover-primary'>
              <Icon.FileEarmarkCheck />
            </Link>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'order_notice',
      hidden: ['invoices', 'credits'].includes(documentType),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.NOTE' }),
      headerClasses: 'text-center pl-5 pr-15',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        return (
          cell && <OverlayTrigger overlay={<Tooltip id="note-tooltip"><div className='text-left'>{cell}</div></Tooltip>}>
            <div className='svg-icon text-dar-50'>
              <Icon.ChatSquareText />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: 'invoice_uploaded',
      hidden: !['invoices'].includes(documentType) || !isAmazonInvoiceUpload,
      text: intl({ id: 'GENERAL.UPLOAD' }),
      headerClasses: 'text-center pl-5 pr-15',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        const uploadSuccess = row.history?.find(history => history.api === "amazon" && history.action === "invoice_uploaded" && history.status === "success");
        const uploadFailed = row.history?.find(history => history.api === "amazon" && history.action === "invoice_uploaded" && history.status === "fail");
        const uploadInfo = <div>
          <div>
            {UIDates.formatDateTime((uploadSuccess ?? uploadFailed)?.created_at)}
          </div>
          <span>
            {uploadSuccess ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.SUCCESS' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : uploadFailed ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.FAIL' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : null}
          </span>
        </div>
        return ['amazon', 'amazon_prime'].includes(row.marketplace)
          ? <OverlayTrigger overlay={<Tooltip id="note-tooltip">{uploadInfo}</Tooltip>}>
            <div className='svg-icon text-dar-50'>
              {(cell && uploadSuccess) ? <Icon.FileEarmarkArrowUp className="text-success" /> : (!cell && uploadFailed) ? <Icon.FileEarmarkMinus className="text-danger" /> : null}
            </div>
          </OverlayTrigger>
          : null
      },
    },
    {
      dataField: 'credit_uploaded',
      hidden: !['credits'].includes(documentType) || !isAmazonCreditUpload,
      text: intl({ id: 'GENERAL.UPLOAD' }),
      headerClasses: 'text-center pl-5 pr-15',
      classes: 'text-center pl-5 pr-15',
      formatter: (cell, row) => {
        const uploadSuccess = row.history?.find(history => history.api === "amazon" && history.action === "credit_uploaded" && history.status === "success");
        const uploadFailed = row.history?.find(history => history.api === "amazon" && history.action === "credit_uploaded" && history.status === "fail");
        const uploadInfo = <div>
          <div>
            {UIDates.formatDateTime((uploadSuccess ?? uploadFailed)?.created_at)}
          </div>
          <span>
            {uploadSuccess ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.SUCCESS' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : uploadFailed ? <FormattedMessage id='DOCUMENT.TABLE_UPLOAD.FAIL' values={{ documentTitle: UIProps.requestedDocument.title, marketplace: getConnection(row?.marketplace)?.label }} /> : null}
          </span>
        </div>
        return ['amazon', 'amazon_prime'].includes(row.marketplace)
          ? <OverlayTrigger overlay={<Tooltip id="note-tooltip">{uploadInfo}</Tooltip>}>
            <div className='svg-icon text-dar-50'>
              {(cell && uploadSuccess) ? <Icon.FileEarmarkArrowUp className="text-success" /> : (!cell && uploadFailed) ? <Icon.FileEarmarkMinus className="text-danger" /> : null}
            </div>
          </OverlayTrigger>
          : null
      },
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      classes: 'hover-menu',
      formatter: columnFormatters.ResponsiveActionFormatter,
      formatExtraData: {
        openPrintDocumentDialog,
        openEditDocumentDialog: UIProps.openEditDocumentDialog,
        copyDocumentForCreateNewOne: UIProps.copyDocumentForCreateNewOne,
        openDeleteDocumentDialog: UIProps.openDeleteDocumentDialog,
        openExportDocumentDialog: UIProps.openExportDocumentDialog,
        documentType: documentType,
        documentTitle: documentTitle,
        documentConvertHandler: documentConvertHandler,
        ...columnHelpers.getFormatterExtraEvents(documentType, UIProps),
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={documents || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes='table table-head-custom overflow-hidden table-vertical-center'
        bootstrap4
        bordered={false}
        striped
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={useLoading} onClick={() => setFilterQuery('DELETE_PARAMS', UIHelpers.allQueryKeys)} />}
        selectRow={remoteGetSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: documents,
          key: documentType,
        })}
      //defaultSorted={[{ dataField: documentType === 'orders' ? "order_date" : "created_at", order: "desc" }]}
      // defaultSorted={columnHelpers.defaultSorted}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countDocuments}
        entitiesCount={documents?.length}
        loading={useLoading}
      />
    </>
  );
}
